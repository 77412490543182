import { Modal } from "react-bootstrap";
import { useState, useRef, useEffect } from 'react'
import PubSub from "pubsub-js";

import './index.css'
import VxImg from '../../assets/img/vx.jpg'

import { SendVerifyCode, LoginByPhone } from '../../api'
import { saveUserInfo } from '../../common'

export const Login = () => {
    // 表单双向绑定
    const formInitialDetails = {
        phone: '',
        verify_code: '',
    }
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    // 更新表单字段
    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }
    // 是否显示弹窗
    const [show, setShow] = useState(false)
    // 验证码文字
    const [disableSend, setDisableSend] = useState(false)
    const [sendcodeText, setSendcodeText] = useState('获取验证码')
    // 表单校验错误提示
    const [phoneInvalidMsg, setphoneInvalidMsg] = useState('');
    const [verifyCodeMsg, setverifyCodeMsg] = useState('');
    // 是否同意协议的checkbox
    const checkboxRef = useRef()

    // 订阅全局登录通知
    useEffect(() => {
        PubSub.subscribe('login', () => setShow(true))
        return
    }, [])
    // 显示微信扫码图片
    useEffect(() => {
        if (show) {
            const state = Date.now().valueOf()
            localStorage.setItem('0927', state)
            new window.WxLogin({
                appid: "wxe59054dfd82db847",
                redirect_uri: encodeURIComponent("https://www.chatlaw.cloud/"),
                response_type: 'code',
                scope: "snsapi_login",
                self_redirect: false,
                id: "vx-login",
                state: state,
            });
        }
    }, [show])

    // 校验手机号是否正确
    const checkPhoneNumber = () => {
        const res = /^1[3-9]\d{9}$/.test(formDetails.phone)
        setphoneInvalidMsg(res ? '' : '请输入正确的手机号')
        return res
    }

    // 校验验证码是否正确
    const checkVerifyCode = () => {
        const res = /^\d{6}$/.test(formDetails.verify_code)
        setverifyCodeMsg(res ? '' : '请输入6位数字验证码')
        return res
    }

    // 发送验证码
    const sendCode = async (e) => {
        // 禁用按钮
        setDisableSend(true)
        // 阻止冒泡
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        // 先校验手机
        if (!checkPhoneNumber()) {
            return
        }
        setSendcodeText('60秒后可重发')
        // 倒计时
        let cutdowm = 59
        const timer = setInterval(() => {
            if (cutdowm == 0) {
                setSendcodeText('获取验证码')
                setDisableSend(false)
                clearInterval(timer)
            } else {
                setSendcodeText(cutdowm + '秒后可重发')
                cutdowm--
            }
        }, 1000)
        //  请求
        const res = await SendVerifyCode({
            phone: formDetails.phone
        })
    }

    // 登录
    const handleSubmit = async () => {
        console.log("表单提交")
        // 校验
        if (checkPhoneNumber() && checkVerifyCode() && checkboxRef.current.checked) {
            const res = await LoginByPhone(formDetails)
            const result = await res.json()
            if (result.status == 'ok') {
                const { phone, token, token_expire_ts, test } = result
                // 本地保存信息
                saveUserInfo({
                    phone, token, token_expire_ts, test, platform: 'phone'
                })
                // 发布消息，刷新用户信息
                PubSub.publish('refresh')
                PubSub.publish('showAlert', '登录成功')
                // 清空表单
                setFormDetails(formInitialDetails)
                setShow(false)
            } else {
                setverifyCodeMsg('验证码错误')
            }
        }
    }


    return (
        <>
            <Modal className="login-modal" show={show} onHide={() => { setShow(false) }}>
                {/* 绝对定位的小组件 */}
                <span className="close-btn" onClick={() => { setShow(false) }}>×</span>
                <span className="login-tip">登录视为您已同意第三方账号绑定协议、服务条款、隐私政策</span>
                <div className="login-icon"></div>
                <div className="login-icon-text">注册</div>
                {/* 左侧：扫码登录 */}
                <div className="login-left">
                    <div className="scan-container" id="vx-login" />
                </div>
                {/* 右侧：手机号、账号密码 */}
                <div className="login-right">
                    <div className="login-method">
                        <span>账号密码登录</span>
                        <span className="choosen-method">手机号登录</span>
                    </div>
                    {/* 手机登录表单 */}
                    <div className="form">
                        {/* 手机号 */}
                        <div className="login-field">
                            <input
                                className={phoneInvalidMsg == "" ? "login-input" : "login-input input-invalid"}
                                type="tel"
                                value={formDetails.phone}
                                placeholder="手机号"
                                onChange={(e) => onFormUpdate('phone', e.target.value)}
                                onBlur={checkPhoneNumber} />
                            <span className={phoneInvalidMsg == "" ? "login-validMsg" : "login-invalidMsg"}>{phoneInvalidMsg}</span>
                        </div>
                        {/* 验证码 */}
                        <div className="login-field">
                            <input
                                /* 验证码自动填充*/
                                id="single-factor-code-text-field"
                                type="text"
                                autoComplete="one-time-code"
                                aria-autocomplete="one-time-code"
                                inputMode="numeric"
                                /* 验证码自动填充*/
                                className={verifyCodeMsg == "" ? "login-input" : "login-input input-invalid"}
                                value={formDetails.verify_code}
                                placeholder="验证码"
                                onChange={(e) => onFormUpdate('verify_code', e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter") {
                                        handleSubmit()
                                    }
                                }}
                            />
                            <span className={verifyCodeMsg == "" ? "login-validMsg" : "login-invalidMsg"}>{verifyCodeMsg}</span>
                            <button disabled={disableSend} className="sendcode-btn" onClick={sendCode}>{sendcodeText}</button>
                        </div>
                        <div className="login-field">
                            <div className="login-protocol">
                                <input type="checkbox" ref={checkboxRef} />
                                <span>我已阅读并同意</span><span>服务条款、隐私政策</span>
                            </div>
                            <button className="login-btn" type="submit" onClick={handleSubmit}>登  录</button>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    )
}