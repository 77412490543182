import { FileEarmarkText, ArrowRightCircle, HourglassSplit } from 'react-bootstrap-icons'
import { useState, useEffect } from 'react'
import PubSub from 'pubsub-js'

import './magazine.css'

import { ApplyUsage } from '../../api'
import { getUserInfo, saveUserInfo } from '../../common'


export const Magazine = ({ title, content, pic, direction }) => {
    const [userInfo, setUserInfo] = useState(getUserInfo())

    // 判断是否已内测
    useEffect(() => {
        // 登录成功/注销会刷新用户状态
        PubSub.subscribe('refresh', () => {
            setUserInfo(getUserInfo())
        })
        return;
    }, [])

    // 申请内测
    const Apply = async () => {
        // 未登录则登录
        if (!userInfo) {
            PubSub.publish('login')
        } else {
            // 0:未申请则申请
            if (userInfo.test == 0) {
                const { platform, unionid, phone } = userInfo
                const res = await ApplyUsage({ user_id: platform == 'phone' ? phone : unionid })
                const result = await res.json()
                if (result.status == 'ok') {
                    // 置为待审核
                    userInfo.test = 1
                    saveUserInfo(userInfo)
                    // 弹窗提示
                    PubSub.publish('showAlert', '申请成功')
                } else {
                    PubSub.publish('showAlert', '出错了', 'danger')
                }
            }
            // 1:已申请:审核中
            // 2:已申请则跳转
            if (userInfo.test == 2) {
                window.location.href = encodeURI(`https://chatlaw.cloud/chat/#/?c2306=${localStorage.getItem('2306')}`)
            }
        }
    }


    return (
        <div className='card' style={{ 'flexDirection': direction == 'left' ? 'row' : 'row-reverse' }}>
            <div className='card-text'>
                <div>
                    <div className='card-title'>{title}</div>
                    <div className='card-content'>{content}</div>
                </div>
                <button
                    disabled={userInfo && userInfo.test == 1}
                    className={userInfo && userInfo.test == 1 ? "card-btn applied-btn" : "card-btn vvd"}
                    onClick={Apply}>
                    {(userInfo == null || userInfo.test == 0) ?
                        // 申请内测
                        <FileEarmarkText size={24} className="banner-icon"></FileEarmarkText> :
                        userInfo.test == 2 ?
                            // 前往使用
                            <ArrowRightCircle size={24} className="banner-icon"></ArrowRightCircle> :
                            //  内测排队中
                            <HourglassSplit size={24} className="banner-icon"></HourglassSplit>
                    }
                    {(userInfo == null || userInfo.test == 0) ? "申请内测" : userInfo.test == 2 ? "前往使用" : "内测排队中"}
                </button>
            </div>
            <video muted autoPlay loop className='pic'>
                <source src={pic} type="video/mp4"></source>
            </video>
        </div>
    )
}