import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import PubSub from 'pubsub-js';
import { useState, useEffect } from 'react'
import { Alert } from 'react-bootstrap'

import 'animate.css'

import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Main } from './components/Main'
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Login } from './components/Login'
import { Logout } from './components/Login/logout'

import { GetNewUserInfo, LoginByVX } from './api'
import { getUserInfo, saveUserInfo, clearUserInfo } from './common'

function App() {
  // 顶部提示框文本
  const [alertMsg, setAlertMsg] = useState('')
  const [alertType, setAlertType] = useState('success')


  // 获得最新用户态
  const checkUserInfo = async () => {
    const userInfo = getUserInfo()
    // 有登录态
    if (userInfo != null) {
      const res = await GetNewUserInfo(userInfo.token)
      const result = await res.json()
      // 更新信息
      userInfo.test = result.test
      userInfo.token = result.token
      userInfo.token_expire_ts = result.token_expire_ts
      if (result.status == 'ok') {
        saveUserInfo(userInfo)
      } else {
        clearUserInfo()
      }
    }
  }

  // 获得query参数 
  const parseSearchParams = (searchParamsString) => {
    return searchParamsString.split('&').reduce((searchParams, curKV) => {
      const [k, v] = curKV.split('=').map(decodeURIComponent);
      searchParams[k] = v;

      return searchParams;
    }, {});
  }

  // 获得微信回调登录code
  const checkVxCode = async () => {
    const { code, state } = parseSearchParams(window.location.search.substr(1))
    // 有code，换取token
    if (code) {
      // 校验
      if (state == localStorage.getItem('0927')) {
        const res = await LoginByVX(code)
        const result = await res.json()
        if (result.status == 'ok') {
          // 发布消息，刷新用户信息
          PubSub.publish('refresh')
          PubSub.publish('showAlert', '登录成功')
          const { token, token_expire_ts, test, unionid } = result
          saveUserInfo({
            token, token_expire_ts, test, unionid, platform: 'wechat'
          })
        } else {
          PubSub.publish('showAlert', '登陆失败', 'danger')
        }
      } else {
        PubSub.publish('showAlert', '登陆失败', 'danger')
      }
    }
  }


  useEffect(() => {
    // 订阅弹窗事件
    PubSub.subscribe('showAlert', (msgTitle, msg, msgType) => {
      setAlertMsg(msg)
      if (msgType) {
        setAlertType(msgType)
      }
      setTimeout(() => {
        setAlertMsg('')
        setAlertType('success')
      }, 1500)
    })
    // 获得最新用户信息
    checkUserInfo()
    // 检查是否是微信登录
    checkVxCode()
  }, [])

  return (
    <div className="App">
      <NavBar />
      <Alert show={alertMsg != ''} variant={alertType} className="animate__animated animate__fadeIn">{alertMsg}</Alert>
      <Logout />
      <Banner />
      <Main />
      <Login />
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
