import PubSub from "pubsub-js"
import Crypto from 'crypto-js'

// 存储用户信息
export const saveUserInfo = (userInfo) => {
    // 加密
    const value = Crypto.AES.encrypt(JSON.stringify(userInfo), 'chatlaw2306').toString()
    localStorage.setItem('2306', value)
    PubSub.publish('refresh')
}

// 获取用户信息
export const getUserInfo = () => {
    const userInfoStr = localStorage.getItem('2306')
    if (!userInfoStr) {
        return null
    }
    const value = Crypto.AES.decrypt(userInfoStr, 'chatlaw2306').toString(Crypto.enc.Utf8)
    try {
        const userInfo = JSON.parse(value)
        const nowTS = new Date().valueOf() / 1000
        // 判断token是否过期
        return userInfo.token_expire_ts > nowTS ? userInfo : null
    } catch (error) {
        return null;
    }
}

// 注销
export const clearUserInfo = () => {
    localStorage.removeItem('2306')
    PubSub.publish('refresh')
}