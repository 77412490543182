import { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { People } from 'react-bootstrap-icons'
import Logo from '../../assets/img/logo-trans.png'

import { getUserInfo } from '../../common'

import PubSub from "pubsub-js";

import './index.css'

export const NavBar = () => {
  // 监听滑动，固定顶部
  const [scrolled, setScrolled] = useState(false);
  // 是否登录
  const [isLogin, setIsLogin] = useState(false)
  useEffect(() => {
    // 监听滑动事件固定顶部
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }
    window.addEventListener("scroll", onScroll);
    // 判断是否登录
    if (getUserInfo()) {
      setIsLogin(true)
    }
    // 订阅用户信息刷新事件
    PubSub.subscribe('refresh', () => {
      // 判断是否登录
      if (getUserInfo() != null) {
        setIsLogin(true)
      } else {
        setIsLogin(false)
      }
    })

    // 卸载监听
    return () => window.removeEventListener("scroll", onScroll);
  }, [])



  return (
    <>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <div className="top-bar">
          <a href="/" className="logo-container">
            <img src={Logo} className="logo" alt="logo"></img>
            <div className="logo-text">ChatLaw</div>
          </a>
          <a className="apply" onClick={() => {
            // 注销或登录
            isLogin ? PubSub.publish('logout') : PubSub.publish('login')
          }}>
            <div className="navbar-btn">
              {isLogin ? '注销' : '登录'}
              <People className="nav-icon" size={26}></People>
            </div>
          </a>
        </div>
      </Navbar>
    </>
  )
}
