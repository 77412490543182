import { At, Building } from 'react-bootstrap-icons'

import vx from '../../assets/img/wechat.jpg'

import './index.css'

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-top">
          {/* 联系方式 */}
          <div className="footer-item">
            <div className="footer-title">Reach Us</div>
            {/* <p className="align-items-start"><Telephone size={20} className="mx-2"></Telephone>100868826</p> */}
            <p><At size={18} className="mx-2"></At>iamzoey429@163.com</p>
            <p><Building size={18} className="mx-2"></Building>深圳市南山区北京大学深圳研究生院</p>
          </div>
          {/* 链接 */}
          <div className="footer-item">
            <div className="footer-title">Quick Link</div>
            <p>About Us</p>
            <a href="#connect">
              <p>Contact Us</p>
            </a>
          </div>
          {/* 社交媒体 */}
          <div className="footer-item">
            <div className="footer-title">Follow Us</div>
            <div className="social-icon mt-2">
              {/* <a href="#"><img src={navIcon1} alt="Icon" /></a>
              <a href="#"><img src={navIcon2} alt="Icon" /></a>
              <a href="#"><img src={navIcon3} alt="Icon" /></a> */}
              <img src={vx}></img>
            </div>
          </div>
        </div>
        {/* 底部名称 */}
        <div className="footer-bottom text-center my-3">
          <div className="footer-name">ChatLaw</div>
          <div className="py-4">Copyright 2023. All Rights Reserved <a className='footer-link' href='https://beian.miit.gov.cn/'>桂ICP备2023000177号-2</a></div>
        </div>
      </div>
    </footer>
  )
}
