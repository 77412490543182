const BaseUrl = "https://chatlaw.cloud/chatlaw/"


// 发送验证码
export function SendVerifyCode(params) {
    return fetch(BaseUrl + "send_verify_code", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(params),
        mode: 'cors'
    });
}

// 根据手机验证码登录
export function LoginByPhone(params) {
    return fetch(BaseUrl + "login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(params),
        mode: 'cors'
    });
}

// 根据微信登录
export function LoginByVX(code) {
    return fetch(BaseUrl + "wx_login?code=" + code, {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        mode: 'cors',
    });
}

// 获得最新用户信息
export function GetNewUserInfo(token) {
    return fetch(BaseUrl + "get_user_info", {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Token": token
        },
        mode: 'cors'
    });
}

// 申请内测
export function ApplyUsage(params) {
    return fetch(BaseUrl + "apply_test", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(params),
        mode: 'cors'
    });
}


// 商务合作
export function BusinessCooperation(params) {
    return fetch(BaseUrl + "send_email", {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            "X-Token": 'CpenZRg3sGmU2F7QBxojzbdMyvHX84h0'
        },
        body: JSON.stringify(params),
        mode: 'cors'
    });
}