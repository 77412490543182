import { Magazine } from './magazine'

import './index.css'

import senseBg from '../../assets/img/sense-bg.png'

export const Main = () => {
    const initInfoList = [
        {
            title: '结构化抽取',
            content: '中文专有模型，支持读取文件、录音，处理多种复杂场景，一键自动抽取关键信息。支持对关键事实进行归纳梳理，生成导图、图表，可视化分析。',
            pic: 'https://chatlaw.cloud/Q1_batch.mp4'
        },
        {
            title: '法律知识注入',
            content: '自研先验知识约束算法，基于亿级法律领域结构化语料及专业化人工标注进行模型知识注入，确保生成内容无误，避免大模型幻觉问题。',
            pic: 'https://chatlaw.cloud/Q2_batch.mp4'
        },
        {
            title: '自动化写作',
            content: '写作模型基于百万级法律文书语料进行微调，一键精准归纳用户事实，自动化进行法律文书写作。',
            pic: 'https://chatlaw.cloud/Q3_batch.mp4'
        }
    ]
    return (
        <div className="main">
            <div className="sense-container">
                <video muted autoPlay loop className="sense">
                    <source src="https://chatlaw.cloud/Q1_batch.mp4" type="video/mp4"></source>
                </video>
                <img className="sense-bg" src={senseBg}></img>
            </div>
            {initInfoList.map((c, i) => (
                <Magazine key={i} direction={i % 2 == 0 ? 'left' : 'right'} title={c.title} content={c.content} pic={c.pic}></Magazine>
            ))}
        </div>
    )
}
