import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from 'react'

import PubSub from "pubsub-js";

import './logout.css'

import { clearUserInfo } from '../../common'

export const Logout = () => {
    const [show, setShow] = useState(false)

    // 订阅全局登录通知
    useEffect(() => {
        PubSub.subscribe('logout', () => {
            setShow(true)
        })
        return
    }, [])

    return (
        <>
            <Modal className="logou-modal" show={show} onHide={() => { setShow(false) }}>
                <Modal.Body>
                    <p>确定注销登录吗？</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        clearUserInfo()
                        setShow(false)
                        PubSub.publish('showAlert', '成功注销')
                    }}>确定</Button>
                    <Button variant="secondary" onClick={() => setShow(false)}>取消</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}