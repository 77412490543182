import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Send } from 'react-bootstrap-icons'


import successImg from '../../assets/img/success.png'
import contactImg from "../../assets/img/contact-img.svg";

import TrackVisibility from 'react-on-screen';

import './index.css'


import { BusinessCooperation } from '../../api'

export const Contact = () => {
  // Waitlist表单
  const formInitialDetails = {
    name: '',
    company: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  // 按钮文字
  const [buttonText, setButtonText] = useState('提交申请');
  // 状态
  const [status, setStatus] = useState({
    // succes: true, message: '提交成功！'
  });

  // 表单更新事件
  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  // 提交表单
  const handleSubmit = async (e) => {
    // 阻止默认事件
    e.preventDefault();
    setButtonText("提交中...");

    // 将合作内容发送至熊猫哥邮箱
    const { name, company, email, phone, message } = formDetails
    const params = {
      email: 'jiaxicui446@gmail.com',//'1439732669@qq.com'
      subject: 'ChatLaw商务合作',
      message: `
        姓名：${name}
        单位：${company}
        电话：${phone}
        邮箱：${email}
        备注：${message}
      `
    }
    let response = await BusinessCooperation(params)
    setButtonText("Send");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.status == 'ok') {
      setStatus({ success: true, message: '提交成功！' });
    } else {
      setStatus({ success: false, message: '抱歉，提交失败，请等会再试.' });
    }
  };

  return (
    <section className="contact" id="connect">
      {/* 表单 */}
      <Col size={14} md={7}>
        <TrackVisibility>
          {({ isVisible }) =>
            <div>
              <Row><h2 className="contact-title">申请商务合作</h2></Row>
              <form onSubmit={handleSubmit}>
                <Row>
                  <input required type="text" value={formDetails.name} placeholder="姓名" onChange={(e) => onFormUpdate('name', e.target.value)} />
                </Row>
                <Row>
                  <input type="text" value={formDetails.company} placeholder="单位" onChange={(e) => onFormUpdate('company', e.target.value)} />
                </Row>
                <Row>
                  <input required type="tel" value={formDetails.phone} placeholder="电话" onChange={(e) => onFormUpdate('phone', e.target.value)} />
                </Row>
                <Row>
                  <input required type="email" value={formDetails.email} placeholder="邮箱" onChange={(e) => onFormUpdate('email', e.target.value)} />
                </Row>
                <Row>
                  <textarea rows="6" value={formDetails.message} placeholder="备注" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                </Row>
                <Row className="contact-btn-container">
                  <button className="contact-btn" type="submit"><Send className="contact-icon" size={22}></Send><span>{buttonText}</span></button>
                  {
                    status.message &&
                    <div className="apply-msg">
                      <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                    </div>
                  }
                </Row>
              </form>
            </div>}
        </TrackVisibility>
      </Col>
      {/* 图片 */}
      <Col size={10} md={5} >
        <TrackVisibility className="bottom-pic">
          {({ isVisible }) =>
            status.succes ? <img className={isVisible ? "successImg" : ""} src={successImg} alt="Contact Us" /> :
              <img src={contactImg} alt="Contact Us" />
          }
        </TrackVisibility>
      </Col>
    </section>
  )
}
